import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Layout from "../../components/Layout";
import RuntimeProductImage from "../../components/RuntimeProductImage";
import Loader from "../../components/Loader";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery, Link } from "gatsby";

const GET_ARTICLES = gql`
  query ($numArticles: Int!, $cursor: String) {
    articles(first: $numArticles, after: $cursor) {
      edges {
        node {
          handle
          image {
            width
            height
            originalSrc
            url
            altText
          }
          publishedAt
          authorV2 {
            name
          }
          id
          title
          excerpt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const BlogPage = () => {
  const imgData = useStaticQuery(graphql`
    query {
      bannerFluidImage: file(relativePath: { eq: "placeholder.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
      blogFluidImage: file(relativePath: { eq: "blog_icon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
        }
      }
    }
  `);
const image = getImage(imgData.bannerFluidImage);
const blogIcon = getImage(imgData.blogFluidImage);
  const [cursor, setCursor] = useState(null);
  const { data, loading, fetchMore } = useQuery(GET_ARTICLES, {
    
    variables: {
      numArticles: 6,
      cursor: cursor,
    },
  });
  return (
    <Layout>
      <main className="container px-4 mx-auto">
        <h1 className="lg:text-5xl  font-bold leading-tight text-3xl my-8">
          Blog
        </h1>
        {loading ? (
          <Loader />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto my-8">
            {data &&
              data.articles.edges.map((article) => (
                <div className="py-4" key={article.node?.handle}>
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                    <div className="w-full">
                      <div className="w-full flex p-2">
                        <div className="p-2 ">
                          <GatsbyImage
                            image={blogIcon}
                            alt="author"
                            className="w-10 h-10 rounded-full overflow-hidden"
                          />
                        </div>
                        <div className="pl-2 pt-2 ">
                          <p className="font-bold">
                            {article.node.authorV2.name}
                          </p>
                          <p className="text-xs">
                            {article.node.publishedAt.substring(0, 10)}
                          </p>
                        </div>
                      </div>
                    </div>
                    {article.node.image && (
                      <RuntimeProductImage
                        className=" w-full object-cover object-center"
                        storefrontProduct={article.node.image}
                      />
                    )}
                    {!article.node.image && (
                      <GatsbyImage
                        className="w-full max-w-full h-[250px]"
                        image={image}
                        alt="Placeholder"
                      />
                    )}

                    <div className="p-4">
                      <h2 className="tracking-widest text-xs title-font font-bold text-green-400 mb-1 uppercase ">
                        {article.node.title}
                      </h2>
                      <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                        {article.node.excerpt}
                      </h1>
                      <div className="flex items-center flex-wrap ">
                        <Link
                          to={`/blog/${article.node.handle}`}
                          state={{ handle: article.node.handle }}
                          className="text-blue-700  md:mb-2 lg:mb-0"
                        >
                          <p className="inline-flex items-center">
                            Read post
                            <svg
                              className="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                            </svg>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
        {data && data.articles.pageInfo.hasNextPage && (
          <button
            className="text-white block mx-auto mt-8 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            onClick={fetchMore({
              variables: {
                numProducts: 6,
                cursor: data.articles.pageInfo.endCursor,
              },
              updateQuery(prev, { fetchMoreResult }) {
                let mergedData = {
                  ...prev.products,
                };
                mergedData = [
                  ...mergedData.nodes,
                  ...fetchMoreResult.articles.nodes,
                ];

                // Update endCursor
                setCursor(fetchMoreResult.products.pageInfo.endCursor);

                return {
                  mergedData,
                  articles: {
                    edges: [...fetchMoreResult.articles.edges],
                    id: fetchMoreResult.articles.edges.node.id,

                    title: fetchMoreResult.articles.edges.node.title,

                    image: {
                      altText:
                        fetchMoreResult.articles.edges.node.image?.altText,

                      originalSrc:
                        fetchMoreResult.articles.edges.node.image?.originalSrc,

                      width: fetchMoreResult.articles.edges.node.image?.width,

                      height: fetchMoreResult.articles.edges.node.image?.height,

                      url: fetchMoreResult.articles.edges.node.image?.url,
                    },
                    pageInfo: {
                      endCursor: fetchMoreResult.articles.pageInfo.endCursor,

                      hasNextPage:
                        fetchMoreResult.articles.pageInfo.hasNextPage,
                    },
                  },
                };
              },
            })}
          >
            Load More
          </button>
        )}
      </main>
    </Layout>
  );
};
export default BlogPage;
